import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import priceList1 from "../../static/HSCI Wholesaler Price List Book_Sept 1_2021.pdf"
import priceList2 from "../../static/Myson Price Book_June 1_21.pdf"
import priceList3 from "../../static/Parts Price List_July 2021.pdf"

const PricePage = () => (
  <Layout>
    <SEO title="Price List" />
    <h1>Price List</h1>
    <ul className="inline-list priceLists">
      {/*
      unpublished: dec-21 — remove mar 22
      */}
      {/*<li className="priceList">*/}
      {/*  <p className="label">Hydronic Systems Price List</p>*/}
      {/*  <ul className="links-list">*/}
      {/*    <li className="small-text">Effective Sept 1, 2021</li>*/}
      {/*    <li className="clearfix">*/}
      {/*      <span className="icon link download black" />*/}
      {/*      <a href={priceList1} target="_blank">*/}
      {/*        Download Now*/}
      {/*      </a>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</li>*/}
      <li className="priceList">
        <p className="label">Purmo/Myson Price List</p>
        <ul className="links-list">
          <li className="small-text">Effective June 1, 2021</li>
          <li className="clearfix">
            <span className="icon link download black" />
            <a href={priceList2} target="_blank">
              Download Now
            </a>
          </li>
        </ul>
      </li>
      {/*
      unpublished: dec-21 — remove mar 22
      */}
      {/*<li className="priceList">*/}
      {/*  <p className="label">Repair Parts Price List</p>*/}
      {/*  <ul className="links-list">*/}
      {/*    <li className="small-text">Effective July 15, 2019</li>*/}
      {/*    <li className="clearfix">*/}
      {/*      <span className="icon link download black" />*/}
      {/*      <a href={priceList3} target="_blank">*/}
      {/*        Download Now*/}
      {/*      </a>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</li>*/}
    </ul>
  </Layout>
)

export default PricePage
